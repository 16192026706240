<template>
  <div
    v-if="data"
    class="list-item has-text-centered has-text-weight-bold"
    @click="$emit('click',data)"
  >
    <div class="image-container">
      <img
        :src="getImagePath(data)"
        :alt="data.title"
        class="image-list"
      >
    </div>
    <div class="has-text-line-height-1 is-size-7 mb-1">
      {{ data.title }}
    </div>
  </div>
</template>


<script>
import ImageUtils from '@/utils/image'

export default {
  name: "GenericCategoryItem",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      LEVELS: {
        'rojo': 'red-bg',
        'naranja': 'orange-bg',
        'azul': 'blue-bg',
        'verde': 'green-bg'
      }
    }
  },
  computed: {
    levelClass() {
      if (!this.data ||
        !this.data.hasOwnProperty('level') ||
        !this.LEVELS.hasOwnProperty(this.data.level)
      ) {
        return ''
      }

      return this.LEVELS[this.data.level]
    },
    hasLevel() {
      return this.levelClass !== ''
    }
  },
  methods: {
    getImagePath(media) {
      const src = media.cover !== '' ? media.cover : media.cover_alt

      return ImageUtils.getCDNFullPath(src)
    }
  }
}
</script>

<style scoped lang="scss">
  .image-container{
    max-width: 200px;
    max-height: 200px;
    position: relative;
  }
  .has-text-line-height-1{
    line-height: 1rem;
  }
</style>
