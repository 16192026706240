<template>
  <div
    v-if="data"
    class="page has-background-blue with-title"
  >
    <div class="columns is-multiline is-mobile">
      <div class="column pb-0 is-gapless is-12">
        <div class="gapless">
          <img
            :src="getImagePath(data.image)"
            :alt="data.title"
            width="100%"
          >
        </div>
      </div>
      <div class="column is-12 has-background-white">
        <div class="main-title px-5">
          <div class="is-flex is-justify-content-space-between mb-1 is-align-items-center">
            <h4 class="subtitle mb-0">
              {{ data.title }}
            </h4>
            <SharePage
              :url="currentUrl"
              :title="data.title"
            />
          </div>
          <div class="is-size-7 mb-3 has-text-weight-semibold has-text-primary">
            <b-icon icon="calendar" />
            {{ data.start_at | prettyDate }}
            <b-icon icon="folder" />
            {{ data.category.title }}
          </div>
        </div>
        <div class="content px-5">
          <div
            class="has-text-grey-dark"
            v-html="data.content"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtils from "@/utils/image";
import SharePage from "@/components/SharePage.vue";

export default {
  name: 'ArticleDetail',
  components: {SharePage},
  title: 'Noticia',
  data: function () {
    return {
      section_title: 'Noticia',
      data: null
    }
  },
  computed: {
    emptyResult() {
      return this.school === null
    },
    userToken() {
      return this.$store.state.token
    },
    currentUrl() {
      return window?.location?.href || ''
    }
  },
  watch: {
    userToken() {
      this.loadContent()
    }
  },
  beforeMount() {
    this.loadContent()
  },
  methods: {
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')
        const id = this.$route.params.id
        this.data = await this.$api.article.getItemById(id)

        this.setPageTitle(this.data.title)

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    }
  }
}
</script>
