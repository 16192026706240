<template>
  <div
    class="p-2"
  >
    <generic-category-item
      v-for="item in categories"
      :key="item.id"
      :data="item"
      @click="openCategory(item)"
    />
  </div>
</template>

<script>

import GenericCategoryItem from "@/components/lists/GenericCategoryItem";

export default {
  name: "ArticleRightSidebar",
  components: {GenericCategoryItem},
  props: {
    value: {
      type: String,
      default: null
    },
    categories: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    openCategory(category) {
      this.$emit('input', category.id)
    }
  }
}
</script>

<style scoped>

</style>
