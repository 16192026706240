<template>
  <div class="is-flex is-align-items-center">
    <div class="mr-2">
      Compartir:
    </div>
    <ShareNetwork
      v-for="network in networks"
      :key="network"
      class="mr-2"
      :network="network"
      :title="title"
      :description="description"
      :url="url"
    >
      <b-icon
        pack="fab"
        size="is-medium"
        :icon="icons[network]"
      />
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: 'SharePage',
  props: {
    title: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    networks: {
      type: Array,
      default: () => ['twitter', 'facebook', 'whatsapp']
    }
  },
  data: () => {
    return {
      icons: {
        twitter: 'twitter',
        facebook: 'facebook',
        whatsapp: 'whatsapp',
        instagram: 'instagram'
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
