<template>
  <div
    v-if="categories"
    class="page has-background-blue with-title"
  >
    <h1 class="page-title has-text-weight-bold has-text-centered is-size-5">
      {{ section_title }}
    </h1>
    <div
      class="container p-4"
    >
      <div class="columns is-multiline is-mobile">
        <article-list-item
          v-for="item in articles"
          :key="item.id"
          :data="item"
          @click="openContent(item)"
        />
        <div
          v-if="emptyResult"
          class="column is-12-mobile is-6-tablet is-offset-3-tablet"
        >
          <div class="no-results">
            No se han encontrado resultados.
          </div>
        </div>
      </div>
    </div>
    <right-side-menu
      v-click-outside="closeRightSidebar"
      :width="sidebarWidth"
      :opened="rightSideOpen"
      @open="rightSideOpenChange"
    >
      <article-right-sidebar
        v-model="selectedCategory"
        :categories="categories"
      />
    </right-side-menu>
  </div>
</template>

<script>

import RightSideMenu from "@/components/RightSideMenu";
import ArticleRightSidebar from "@/components/sidebars/ArticleRightSidebar";
import ArticleListItem from "@/components/lists/ArticleListItem";

export default {
  name: 'ArticleCategoriesDashboard',
  components: {ArticleListItem, ArticleRightSidebar, RightSideMenu},
  title: 'Comunicaciones',
  data: function () {
    return {
      section_title: 'Comunicación',
      articles: null,
      sidebarWidth: '110px',
      selectedCategory: null,
      categories: null,
      rightSideOpen: false,
    }
  },
  computed: {
    emptyResult() {
      if (!this.articles) {
        return false
      }

      return this.articles.length === 0
    },
    userToken() {
      return this.$store.state.token
    },
    currentCategory() {
      if (!this.selectedCategory || !this.categories) {
        return null
      }

      return this.categories.find((category) => category.id === this.selectedCategory)
    }
  },
  watch: {
    userToken() {
      this.loadCategories()
    },
    selectedCategory(newVal) {
      if (newVal) {
        if (this.currentCategory) {
          this.setPageTitle(this.currentCategory.title)
          this.section_title = this.currentCategory.title
        }
        this.loadArticles()
        this.rightSideOpen = false
        this.$router.push({name: 'articles', hash: '#' + this.selectedCategory})
      }
    }
  },
  beforeMount() {
    if (this.$router.currentRoute.hash.indexOf('#') === 0) {
      this.selectedCategory = this.$router.currentRoute.hash.replace('#', '')
    }
  },
  mounted() {
    this.loadCategories()
  },
  methods: {
    async loadCategories() {
      try {
        await this.$store.dispatch('initLoading')

        this.categories = await this.$api.article.getList()

        if (!this.selectedCategory) {
          this.selectedCategory = this.categories[0].id
        }

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    },
    async loadArticles() {
      try {
        await this.$store.dispatch('initLoading')

        this.articles = await this.$api.article.getListByCategory(this.selectedCategory)

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    },
    openContent(item) {
      const id = item.id
      this.$router.push({name: "articles-detail", params: {id}})
    },
    closeRightSidebar() {
      this.rightSideOpen = false
    },
    rightSideOpenChange(open) {
      this.rightSideOpen = open
    },
  }
}
</script>
